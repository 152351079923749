import { ConfigurationTranslations } from '@box-types';

export const elConfigTranslations: ConfigurationTranslations = {
  strings: {
    'tutorial-bubble-text': 'Πάρε μέρος στον διαγωνισμό και μπορεί να είσαι εσύ ένας από τους νικητές!',
    'enf-fee-explanation':
      'Είναι μια χρέωση ο σκοπός της οποίας είναι να υποστηρίξει τις προσπάθειες βιωσιμότητας, να μειώσει τα αποτυπώματα άνθρακα ή να επενδύσει σε πρακτικές φιλικές προς το περιβάλλον.',
    'delivery-fee-explanation':
      'Το ποσό αυτό υπολογίζεται ανάλογα με την απόσταση που έχει το κατάστημα από το σημείο παράδοσης της παραγγελίας σου.',
    'service-fee-explanation':
      'Μας βοηθά να βελτιώσουμε την υπηρεσία παράδοσης, διασφαλίζοντας έτσι την καλύτερη εμπειρία.',
    'learn-offers': 'Για να μαθαίνεις όλες τις προσφορές!',
    'email-phone-notifications':
      'Θέλουμε να σε ενημερώνουμε στο e-mail και στο κινητό σου για όλα τα νέα του BOX, για να μην χάνεις:',
    'discounts-coupons-gifts': 'Eκπτώσεις, κουπόνια και μεγάλες προσφορές!',
    'new-shops': 'Nέα καταστήματα που θες να δοκιμάσεις!',
    'default-sorting-explanation':
      'Η ταξινόμηση των αποτελεσμάτων γίνεται βάσει αλγορίθμου που λαμβάνει υπόψιν: τον αριθμό παραγγελιών των καταστημάτων στην περιοχή σου τις τελευταίες 15 ημέρες, τη δημοτικότητά τους κατά την κρίση του BOX, το χρόνο ένταξής τους στο BOX, το ωράριό τους, καθώς και το αν συμμετέχουν σε προωθητικές καμπάνιες.',
    'ratings-sorting-explanation': 'Η ταξινόμηση των αποτελεσμάτων γίνεται με βάση τις καλύτερες αξιολογήσεις',
    'search-sorting-explanation':
      'Η ταξινόμηση των αποτελεσμάτων γίνεται με βάση τη σχετικότητα της λέξης-κλειδί που αναζήτησες',
    'cuisine-sorting-explanation':
      'Η ταξινόμηση των αποτελεσμάτων γίνεται βάσει αλγορίθμου που λαμβάνει υπόψιν: τον αριθμό παραγγελιών των καταστημάτων στην περιοχή σου τις τελευταίες 15 ημέρες, τη δημοτικότητά τους κατά την κρίση του BOX, το χρόνο ένταξής τους στο BOX, το ωράριό τους, καθώς και το αν συμμετέχουν σε προωθητικές καμπάνιες ή πρόκειται για αλυσίδες και φέρουν τη σήμανση AD.',
    'minimum-order-sorting-explanation':
      'Η ταξινόμηση των αποτελεσμάτων γίνεται με βάση τη μικρότερη ελάχιστη αξία παραγγελία',
    'delivery-time-sorting-explanation':
      'Η ταξινόμηση των αποτελεσμάτων γίνεται με βάση το συντομότερο χρόνο παράδοσης',
    'first-step': 'Το πρώτο βήμα έγινε!',
    'make-first-order': 'Κάνε τώρα την πρώτη σου παραγγελία και ξεκίνα να μαζεύεις συμμετοχές.',
    'redeem-bonus-points': 'Εξαργύρωσε τώρα τους Bonus πόντους σου και κέρδισε έκπτωση!',
    'verify-bonus-card':
      'Επιβεβαίωσε τα στοιχεία της Bonus κάρτας σου για να μπορείς να εξαργυρώνεις Bonus πόντους στις παραγγελίες σου στο ΒΟΧ!',
    'tip-explanation':
      'Το φιλοδώρημα θα το λάβει εξ ολοκλήρου ο διανομέας! Μπορείς να επιλέξεις από MINIMUM_PRICE_TEXT ως MAXIMUM_PRICE_TEXT."',
    'eke-explanation':
      'Επίλεξε το ποσό που επιθυμείς να δωρίσεις απο MINIMUM_PRICE_TEXT ως MAXIMUM_PRICE_TEXT και θα υπολογιστεί στην τελική τιμή της παραγγελίας.',
    'child-smile': 'Χαμόγελο του Παιδιού',
    'support-child-smile': 'Στήριξε το Χαμόγελο του Παιδιού',
    'box-supports-child-smile':
      'Το BOX στηρίζει το Χαμόγελο του Παιδιού. Επίλεξε το ποσό που επιθυμείς να δωρίσεις και θα υπολογιστεί στην τελική τιμή της παραγγελίας σου.',
    'update-app': 'Ενημέρωσε την εφαρμογή σου!',
    'update-app-reason':
      'Ενημέρωσε το BOX app για να συνεχίσεις να παραγγέλνεις με ασφάλεια, ευκολία και ακόμα περισσότερες προσφορές και οφέλη!',
    'you-always-win': 'Το delivery που πάντα\nκερδίζεις σε κάθε\nμαγαζί & πιάτο',
    'redeem-points-to-discounts-and-gb': 'Εξαργυρώνεις\nπόντους σε GB &\nεκπτώσεις',
    'food-or-groceries': "Φαγητό ή ψώνια;\n'Ο,τι θες σε πάνω από\n10.000 καταστήματα",
    '1600-for-every-4-euro':
      'Για κάθε 4€ παραγγελίας μπορείς να εξαργυρώσεις 1600 πόντους. Για κάθε 1600 πόντους που εξαργυρώνεις κερδίζεις έκπτωση 1€. Για τις κατηγορίες Super Market και Φρέσκα Τρόφιμα για κάθε 10€ παραγγελίας μπορείς να εξαργυρώσεις 1600 πόντους.\n\nΑν περάσουν 90 μέρες χωρίς να έχεις κάνει παραγγελία, οι πόντοι σου διαγράφονται. Μην ανησυχείς, αν ξεχαστείς θα σου στείλουμε ειδοποίηση πριν την διαγραφή τους.\n\nΙσχύς σχήματος πόντων έως 30/06/2024.',
    'gb-with-every-order-from-cosmote': 'Με κάθε παραγγελία σου κερδίζεις GB από την COSMOTE!',
    'pick-mobile-internet-plan':
      'Κατά την ολοκλήρωση της παραγγελίας σου διάλεξε το πακέτο Μοbile Internet που θέλεις και θα το έχεις αμέσως μετά την ολοκλήρωση της!',
    'offer-available-on-cosmote':
      'Η προσφορά είναι διαθέσιμη σε οποιοδήποτε από τα COSMOTE κινητά που έχεις δηλώσει στην πλατφόρμα COSMOTE id και αποδίδεται μόνο σε μία παραγγελία κάθε μέρα.',
    'cosmote-subscribers': 'Συνδρομητές COSMOTE',
    'only-for-cosmote-subscribers': 'Μόνο για τους συνδρομητές Cosmote!',
    'gb-with-every-order-from-wu': "Με κάθε παραγγελία σου κερδίζεις GB από το What's Up!",
    'offer-available-on-wu':
      "Η προσφορά είναι διαθέσιμη σε οποιοδήποτε από τα What's Up κινητά που έχεις δηλώσει στην πλατφόρμα COSMOTE id και αποδίδεται μόνο σε μία παραγγελία κάθε μέρα.",
    'wu-users': 'Whats Up χρήστες',
    'only-for-wu-users': "Μόνο για τους What's Up χρήστες μας!",
    'wu-student-wins': "Οι What's Up Students μας κερδίζουν!",
    'we-love-we-students': "Αγαπάμε τους What's Up Students μας!",
    'only-for-wu-students': "Μόνο για τους What's Up Students μας!",
    'order-now': 'Παράγγειλε τώρα',
    'win-easy': 'Κέρδισε εύκολα ',
    'plus-3200-points': '+3200 πόντοι',
    '3200-points-worth-2': '3.200 πόντους, αξίας 2€, ',
    'every-friend-invite-they-win': 'για κάθε φίλο που προσκαλείς, κι εκείνοι θα κερδίσουν κουπόνια συνολικής αξίας ',
    '2-euro-on-3-orders':
      '(από 2€ σε 3 παραγγελίες).\n\nΜόλις ο φίλος σου κάνει την πρώτη του παραγγελία με τον κωδικό σου, θα κερδίσεις άμεσα κουπόνι με τους πόντους σου!\n\nΗ προσφορά ισχύει έως 30/06/2024.',
    'lets-box-together': 'Έλα να BOXάρουμε παρέα!',
    'redeemed-euros-from-box': 'Με το BOX, έχω κερδίσει μέχρι τώρα _EURO_ από εξαργυρώσεις πόντων και κουπονιών!',
    'redeemed-euros-and-gb-from-box':
      'Με το BOX, έχω κερδίσει μέχρι τώρα _EURO_ από εξαργυρώσεις πόντων και κουπονιών & _GB_GB Internet!',
    'download-app-and-use-coupon-to-win':
      'Έλα να BOXάρουμε παρέα! Κατέβασε το BOX και χρησιμοποίησε το κουπόνι COUPON_CODE για να κερδίσεις συνολικά 6€. Σε κάθε μια από τις 3 πρώτες σου παραγγελίες κερδίζεις 2€ έκπτωση.',
    'download-app-if-not-already-and-use-coupon':
      'Εάν δεν έχεις την εφαρμογή, κατέβασε την και χρησιμοποίησε το κουπόνι COUPON_CODE για να κερδίσεις συνολικά 6€. Σε κάθε μια από τις 3 πρώτες σου παραγγελίες κερδίζεις 2€ έκπτωση.',
    'win-3200-points': 'Κέρδισε 3200 πόντους για κάθε φίλο που προσκαλείς!',
    'they-win-6': '...κι εκείνοι κερδίζουν από 6€!',
    'see-more': 'Δες περισσότερα',
    'e-fresh-card-long': 'e-Fresh Card',
    'e-fresh-card-short': 'e-Fresh Card',
    'masoutis-card-long': 'Μασούτης Mas Card',
    'masoutis-card-short': 'Mas Card',
    'krhtikos-card-long': 'Κρητικός Club Card',
    'krhtikos-card-short': 'Club Card',
    'coffee-?': 'Καφέ;',
    'bagel-?': 'Κουλούρι;',
    'toast-?': 'Τοστ;',
    'croissant-?': 'Κρουασάν;',
    'juice-?': 'Χυμό;',
    'cheese-pie-?': 'Τυρόπιτα;',
    'milk-?': 'Γάλα;',
    'bread-?': 'Ψωμί;',
    'cereal-?': 'Δημητριακά;',
    'fruits-?': 'Φρούτα;',
    'fish-?': 'Ψάρι;',
    'souvlaki-?': 'Σουβλάκι;',
    'cooked-?': 'Μαγειρευτό;',
    'salad-?': 'Σαλάτα;',
    'pizza-?': 'Πίτσα;',
    'burger-?': 'Burger;',
    'ethnic-?': 'Ethnic;',
    'tea-?': 'Τσάι;',
    'ice-cream-?': 'Παγωτό;',
    'dessert-?': 'Γλυκό;',
    'chinese-?': 'Κινέζικο;',
    'sushi-?': 'Sushi;'
  },
  images: {
    gdpr_consent_discounts_coupons_gifts: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'gdpr-gift',
      ext: 'png'
    },
    gdpr_consent_new_shops: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'gdpr-food',
      ext: 'png'
    },
    bank_loyalty_info_bonus_logo: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'bonuslogomodal',
      ext: 'png'
    },
    small_logo_bonus: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'bonuslogo',
      ext: 'png'
    },
    big_logo_bonus: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'bonuslogomodal',
      ext: 'png'
    },
    points_image_bonus: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'bonuspontousV3',
      ext: 'png'
    },
    modal_icon_eke: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'hamogelo_logo',
      ext: 'png'
    },
    checkout_indicator_eke: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'eke_indicator',
      ext: 'png'
    },
    whats_new_page_1: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/',
      name: 'whats_new_page_1_v2',
      ext: 'png'
    },
    whats_new_page_1_dark: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/',
      name: 'whats_new_page_1_dark',
      ext: 'png'
    },
    whats_new_page_2: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/',
      name: 'whats_new_page_2',
      ext: 'png'
    },
    whats_new_page_2_dark: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/',
      name: 'whats_new_page_2_dark',
      ext: 'png'
    },
    whats_new_page_3: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/',
      name: 'whats_new_page_3',
      ext: 'png'
    },
    whats_new_page_3_dark: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/',
      name: 'whats_new_page_3_dark',
      ext: 'png'
    },
    tutorial_gif_order_process: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/tutorials/',
      name: 'Order-ProcessV2',
      ext: 'gif'
    },
    tutorial_gif_points_redemption: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/stage/tutorials/',
      name: 'Points-Redemption-Light-EL',
      ext: 'gif'
    },
    tutorial_gif_gb_reward_claim: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/stage/tutorials/',
      name: 'GB-Rewards-Claim-Light-EL',
      ext: 'gif'
    },
    tutorial_gif_first_card_payment: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/tutorials/',
      name: '1st-Card-Payment-Light-El',
      ext: 'gif'
    },
    mb_skins_cosmote_informative_modal_banner: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'mobile_informative_modal',
      ext: 'jpg'
    },
    mb_skins_cosmote_web_informative_modal_banner: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'web_informative_modal',
      ext: 'png'
    },
    mb_skins_cosmote_logo: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'cosmote-logo',
      ext: 'png'
    },
    mb_skins_whats_up_informative_modal_banner: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'informative_modal_wus',
      ext: 'jpg'
    },
    mb_skins_whats_up_web_informative_modal_banner: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'informative_modal_wus',
      ext: 'jpg'
    },
    mb_skins_whats_up_logo: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'whatsup-logo',
      ext: 'png'
    },
    mb_skins_whats_up_student_offer_indicator: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'whatsup-offer-indicator',
      ext: 'png'
    },
    mb_skins_whats_up_student_informative_modal_banner: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'informative_modal_wus',
      ext: 'jpg'
    },
    mb_skins_whats_up_student_web_informative_modal_banner: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'informative_modal_wus',
      ext: 'jpg'
    },
    mb_skins_whats_up_student_logo: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'whatsup-logo',
      ext: 'png'
    },
    referral_baf_v2: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'BAF_LP-min_v2',
      ext: 'png'
    },
    single_banner_section_v2_mobile: {
      path: 'https://s3.eu-central-1.amazonaws.com/w4ve/box/configuration/stage/images/',
      name: 'BEF_decoration',
      ext: 'png'
    },
    single_banner_section_v2_web: {
      path: 'https://s3.eu-central-1.amazonaws.com/w4ve/box/configuration/stage/images/',
      name: 'BEF_decoration',
      ext: 'png'
    },
    baf_image_model: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'BAF_image_3200',
      ext: 'png'
    },
    app_download_points_info: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'app_download_1600',
      ext: 'jpg'
    },
    sm_loyalty_info_logo_wide_e_fresh: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'e-fresh_card_flow_icon',
      ext: 'png'
    },
    sm_loyalty_info_order_acceptance_logo_e_fresh: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'e-fresh_order_acceptance_logo',
      ext: 'png'
    },
    sm_loyalty_info_checkout_discount_logo_e_fresh: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'e-fresh_loyalty_card_logo',
      ext: 'png'
    },
    sm_loyalty_info_big_card_placeholder_e_fresh: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'e-fresh-big-card-placeholder',
      ext: 'png'
    },
    sm_loyalty_info_small_card_placeholder_e_fresh: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'e-fresh-small-card-placeholder',
      ext: 'png'
    },
    sm_loyalty_info_order_history_logo_e_fresh: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'e-fresh_card_logo',
      ext: 'png'
    },
    sm_loyalty_info_logo_wide_masoutis: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'masoutis_card_flow_icon',
      ext: 'png'
    },
    sm_loyalty_info_order_acceptance_logo_masoutis: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'masoutis_order_acceptance_logo',
      ext: 'png'
    },
    sm_loyalty_info_checkout_discount_logo_masoutis: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'masoutis_loyalty_card_logo',
      ext: 'png'
    },
    sm_loyalty_info_big_card_placeholder_masoutis: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'masoutis-big-card-placeholder-new',
      ext: 'png'
    },
    sm_loyalty_info_small_card_placeholder_masoutis: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'masoutis-small-card-placeholder',
      ext: 'png'
    },
    sm_loyalty_info_order_history_logo_masoutis: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'masoutis_card_logo',
      ext: 'png'
    },
    sm_loyalty_info_logo_wide_kritkos: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'kritikos-wide',
      ext: 'png'
    },
    sm_loyalty_info_order_acceptance_logo_kritikos: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'kritikos-order-acceptance',
      ext: 'png'
    },
    sm_loyalty_info_checkout_discount_logo_kritikos: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'kritikos-card-checkout',
      ext: 'png'
    },
    sm_loyalty_info_big_card_placeholder_kritikos: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'kritikos-big-card-placeholder-new',
      ext: 'png'
    },
    sm_loyalty_info_small_card_placeholder_kritikos: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'kritikos-guest-card-placeholder',
      ext: 'png'
    },
    sm_loyalty_info_order_history_logo_kritikos: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'kritikos-order-history-logo',
      ext: 'png'
    },
    web_landing_heroes_1_png_xl: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/morning/',
      name: 'morning',
      ext: 'png'
    },
    web_landing_heroes_1_png_lg: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/morning/',
      name: 'morning',
      ext: 'png'
    },
    web_landing_heroes_1_png_md: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/morning/',
      name: 'morning',
      ext: 'png'
    },
    web_landing_heroes_1_png_sm: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/morning/',
      name: 'morning-sm',
      ext: 'png'
    },
    web_landing_heroes_2_png_xl: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/sm/',
      name: 'sm',
      ext: 'png'
    },
    web_landing_heroes_2_png_lg: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/sm/',
      name: 'sm',
      ext: 'png'
    },
    web_landing_heroes_2_png_md: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/sm/',
      name: 'sm',
      ext: 'png'
    },
    web_landing_heroes_2_png_sm: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/sm/',
      name: 'sm-sm',
      ext: 'png'
    },
    web_landing_heroes_3_png_xl: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/lunch/',
      name: 'lunch',
      ext: 'png'
    },
    web_landing_heroes_3_png_lg: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/lunch/',
      name: 'lunch',
      ext: 'png'
    },
    web_landing_heroes_3_png_md: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/lunch/',
      name: 'lunch',
      ext: 'png'
    },
    web_landing_heroes_3_png_sm: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/lunch/',
      name: 'lunch-sm',
      ext: 'png'
    },
    web_landing_heroes_4_png_xl: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/coffee-snacks/',
      name: 'coffee-snacks',
      ext: 'png'
    },
    web_landing_heroes_4_png_lg: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/coffee-snacks/',
      name: 'coffee-snacks',
      ext: 'png'
    },
    web_landing_heroes_4_png_md: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/coffee-snacks/',
      name: 'coffee-snacks',
      ext: 'png'
    },
    web_landing_heroes_4_png_sm: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/coffee-snacks/',
      name: 'coffee-snacks-sm',
      ext: 'png'
    },
    web_landing_earn_webp: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'landing-earn',
      ext: 'webp'
    },
    web_landing_earn_png: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'landing-earn',
      ext: 'png'
    }
  },
  links: {
    terms_link: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/stage/legal/termsAndConditions_EL.html',
    privacy_link: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/stage/legal/privacyNotice_EL.html',
    marketing_terms_link:
      'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/stage/legal/marketingTerms_EL.html',
    faqs: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/stage/faqs/faqs_EL.json',
    sm_loyalty_info_loyalty_terms_e_fresh: 'https://www.e-fresh.gr/el/terms-of-use',
    sm_loyalty_info_find_card_terms_url_e_fresh: 'https://www.e-fresh.gr/el/privacy-policy',
    sm_loyalty_info_personal_data_terms_url_e_fresh: 'https://www.e-fresh.gr/el/programma-epivraveuseis/karta-melous',
    sm_loyalty_info_loyalty_terms_masoutis: 'https://www.masoutis.gr/mascard/Terms',
    sm_loyalty_info_find_card_terms_url_masoutis: 'https://www.masoutis.gr/mascard/Terms',
    sm_loyalty_info_personal_data_terms_url_masoutis: 'https://www.masoutis.gr/MasCard/MasCardBox',
    sm_loyalty_info_loyalty_terms_kritikos: 'https://www.kritikos-easy.gr/terms'
  }
};
