import { elTranslations } from '@box-assets/translations/Web_EL';
import { enTranslations } from '@box-assets/translations/Web_EN';
import { elConfigTranslations } from '@box-assets/translations/ConfigurationV2_EL';
import { enConfigTranslations } from '@box-assets/translations/ConfigurationV2_EN';
import { Translations } from '@box-types';

export default {
  el: { clientTranslations: elTranslations, configTranslations: elConfigTranslations },
  en: { clientTranslations: enTranslations, configTranslations: enConfigTranslations },
  configTranslationsTimestamp: 1726066625
} as Translations;
