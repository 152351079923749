import { Environment } from '../types';
import versionStamp from './version.json';

export const environment: Environment = {
  name: 'Stage',
  code: 'stage',
  production: false,
  server: {
    prerender: {
      enabled: true,
      PRERENDER_TOKEN: 'On4TY4BWPO2hTqd06AcG'
    },
    appRedirection: true,
    areaRedirection: true
  },
  application: {
    VERSION: versionStamp.version,
    RELEASE: versionStamp.release,
    BUILD: versionStamp.build,
    API_URL: 'https://box-wavecxm-stage.herokuapp.com/api',
    ORDERS_API_URL: 'https://box-wavecxm-stage-merchant.herokuapp.com/api',
    ROOT_URL: 'https://stage.box.gr',
    SENTRY_DSN: 'https://7dc4d044b1644c9aaa5288b9563b43bc@sentry.io/1827673',
    CONFIGURATION_URL: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/stage/configurationV2.json',
    APP_ENGINE_HOST: 'box-web-stage.appspot.com',
    GCLOUD_STORAGE_BUCKET: 'box-web-stage.appspot.com',
    legalDocuments: {
      STYLES_URL: 'https://storage.googleapis.com/box-web-stage.appspot.com/legal-documents/styles.css'
    }
  },
  cosmote: {
    ACCOUNT_URL: 'https://accountuat.box.gr',
    EXTSSO_URL: 'https://extssouat.cosmote.gr',
    PAYMENT_URL: 'https://uatatg.cosmote.gr/nbg/begin',
    cosmoteID: {
      CHANNEL_ID: 'foodboxweb',
      CLIENT_ID: 'DSQUAT',
      DOMAIN: 'DSQUATDomain',
      SCOPE: 'DSQUATApp.Info'
    }
  },
  google: {
    MAPS_API_KEY: 'AIzaSyD2Uzq1p1ZAwg9ljONIaGKx_ToV718gOF0',
    STATIC_MAPS_API_KEY: 'AIzaSyB0od2UpWmEVeeTTso51ap1fFYZ_aACxU8',
    MEASUREMENT_ID: 'G-SNXP90WRVM'
  }
};
