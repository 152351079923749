import { GoogleConsent, PrivacyConsent, LegalDocumentVersion, Configuration, LegalDocumentData } from '@box-types';
import { storageGet, storageSet } from '../storage';
import dayjs from 'dayjs';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

const legalDocumentData: { [key: string]: LegalDocumentData } = {
  terms: {
    title: 'use_terms_updated',
    messages: ['u_can_see_updates_in_use_terms_page'],
    routerLink: '/terms'
  },
  privacy: {
    title: 'data_protection_terms_updated',
    messages: ['u_can_see_updates_in_data_protection_terms_page'],
    routerLink: '/privacy'
  },
  personalization: {
    title: 'personalization_terms_updated',
    messages: ['u_can_see_updates_in_personalization_page'],
    routerLink: '/gdpr'
  }
};

// todo refactor duplicate code in index.stage.html index.prod.html
function convertPrivacyConsentToGoogleConsent(privacyConsent: PrivacyConsent): GoogleConsent {
  return {
    ad_user_data: privacyConsent?.ad ? 'granted' : 'denied',
    ad_personalization: privacyConsent?.ad ? 'granted' : 'denied',
    ad_storage: privacyConsent?.ad ? 'granted' : 'denied',
    analytics_storage: privacyConsent?.performance ? 'granted' : 'denied',
    personalization_storage: privacyConsent?.ux ? 'granted' : 'denied'
  };
}

function updateGoogleConsent(privacyConsent: PrivacyConsent): void {
  const googleConsent = convertPrivacyConsentToGoogleConsent(privacyConsent);
  gtag('consent', 'update', googleConsent);
}

function getLegalDocumentVersionFromConfig(config: Configuration): LegalDocumentVersion {
  if (!config) return;
  return {
    terms: config.termsAndConditionsLastUpdateV2,
    privacy: config.privacyNoticeLastUpdateV2,
    personalization: config.marketingTermsLastUpdateV2
  };
}

function legalDocumentUpdated(remoteLegalDocumentVersion: LegalDocumentVersion): string {
  if (!remoteLegalDocumentVersion) return;
  const localVersion = storageGet<LegalDocumentVersion>('Box:legalDocumentVersion', window.localStorage);
  const defaultVersion: LegalDocumentVersion = {
    terms: 0,
    personalization: 0,
    privacy: 0
  };
  const mergedLocalVersion = { ...defaultVersion, ...localVersion };
  const legalDocumentTypes: string[] = ['terms', 'privacy', 'personalization'];

  for (const type of legalDocumentTypes) {
    const localDocumentTS: dayjs.Dayjs = dayjs.unix(mergedLocalVersion[type] as number);
    const newDocumentTS: dayjs.Dayjs = dayjs.unix(remoteLegalDocumentVersion[type] as number);
    if (newDocumentTS.isAfter(localDocumentTS)) return type;
  }
}

function updateLegalDocumentData(type: string, remoteLegalDocumentVersion: LegalDocumentVersion): void {
  if (!remoteLegalDocumentVersion || !type || !remoteLegalDocumentVersion[type]) return;
  const localVersion: LegalDocumentVersion = storageGet('Box:legalDocumentVersion', window.localStorage);
  const newVersion: { [key: string]: number } = {};
  newVersion[type] = remoteLegalDocumentVersion[type] as number;
  storageSet('Box:legalDocumentVersion', { ...localVersion, ...newVersion }, window.localStorage);
}

function getLegalDocumentUpdateData(type: string): LegalDocumentData {
  return legalDocumentData[type];
}

export {
  getLegalDocumentUpdateData,
  updateGoogleConsent,
  convertPrivacyConsentToGoogleConsent,
  getLegalDocumentVersionFromConfig,
  legalDocumentUpdated,
  updateLegalDocumentData
};
