import { Shop, DaasAvailability, DeliveryMethod, CurrencyCode } from '@box-types';
import { minBy, maxBy } from 'lodash-es';
import { currencyFormat, CurrencyFormatOptions } from '../core';

export {
  getShopMinimumPrice,
  getShopEstimationTime,
  getShopEstimationTimeText,
  getShopMinimumOrderPriceText,
  getShopDeliveryFeeText
};

function getShopMinimumPrice(shop: Shop, deliveryMethod: DeliveryMethod = 'delivery'): number {
  return deliveryMethod === 'delivery'
    ? shop.minimumAllowedDeliveryOrderTotalPrice
    : shop.minimumAllowedTakeAwayOrderTotalPrice;
}

function getShopMinimumOrderPriceText(
  shop: Shop,
  deliveryMethod: DeliveryMethod,
  canDeliverToAddress: boolean,
  currencyCode: CurrencyCode
): string {
  if (!canDeliverToAddress) return '-';
  const minimumPrice = getShopMinimumPrice(shop, deliveryMethod);
  const formatOptions: CurrencyFormatOptions = {
    currencyCode,
    symbolSpace: false,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  };
  return currencyFormat(minimumPrice, formatOptions);
}

function getShopEstimationTime(
  shop: Shop,
  deliveryMethod: DeliveryMethod = 'delivery',
  daasAvailability?: DaasAvailability
): number {
  if (shop.isSuperMarket && shop.hasTimeSlots) return 0;
  if (deliveryMethod === 'takeAway') return shop.preparationEstimationTime ?? 0;
  if (shop.daas) {
    if (daasAvailability?.firstAvailableTimeslot) return 0;
    if (daasAvailability?.estimatedDelay > 0) return daasAvailability.estimatedDelay ?? 0;
    // This is a fallback mechanism for good measure, if the projectedDeliveryETA is not provided
    if (shop.projectedDeliveryETA) return shop.projectedDeliveryETA ?? 0;
    return (shop.preparationEstimationTime ?? 0) + (shop.daasAreaShippingTime ?? 0);
  }
  return shop.deliveryEstimationTime ?? 0;
}

function getShopEstimationTimeText(
  shop: Shop,
  deliveryMethod: DeliveryMethod,
  daasAvailability?: DaasAvailability
): string {
  const estimationTime = getShopEstimationTime(shop, deliveryMethod, daasAvailability);
  if (estimationTime > 0) return `${estimationTime}΄`;
}

function getShopDeliveryFeeText(
  shop: Shop,
  deliveryMethod: DeliveryMethod,
  currencyCode: CurrencyCode,
  shortFormat = false
): string {
  if (deliveryMethod === 'takeAway' || !shop.deliveryFees.length) return '0€';
  const minFee = minBy(shop.deliveryFees, 'fee').fee;
  const maxFee = maxBy(shop.deliveryFees, 'fee').fee;
  const minFeeText = currencyFormat(minFee, { symbolSpace: false, currencyCode });
  const maxFeeText = currencyFormat(maxFee, { symbolSpace: false, currencyCode });

  if (minFee === maxFee) return minFeeText;

  if (shortFormat) {
    return `${minFee / 100}-${maxFeeText}`;
  } else {
    return `${minFeeText} - ${maxFeeText}`;
  }
}
