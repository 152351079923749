export const configurationV2: unknown = {
  languages: [
    {
      fileUrl:
        'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/stage/config-strings/ConfigurationV2_EL.json',
      friendlyName: 'Ελληνικά (GR)',
      languageCode: 'el',
      cacheTimestamp: 1725878090
    },
    {
      fileUrl:
        'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/stage/config-strings/ConfigurationV2_EN.json',
      friendlyName: 'English (EN)',
      languageCode: 'en',
      cacheTimestamp: 1725878090
    }
  ],
  legalDocuments: {
    terms: {
      key: 'terms_link',
      type: 'links'
    },
    privacy: {
      key: 'privacy_link',
      type: 'links'
    },
    marketingTerms: {
      key: 'marketing_terms_link',
      type: 'links'
    }
  },
  faqs: {
    key: 'faqs',
    type: 'links'
  },
  happyPathsEnabled: true,
  happyPathsTimePeriodInMonths: 3,
  marketingTermsLastUpdateV2: 0,
  termsAndConditionsLastUpdateV2: 0,
  privacyNoticeLastUpdateV2: 1718704263,
  giftVouchersLastUpdateV2: 0,
  priceCalculation: {
    info: {
      envFee: {
        text: {
          key: 'enf-fee-explanation',
          type: 'strings'
        }
      },
      deliveryFee: {
        text: {
          key: 'delivery-fee-explanation',
          type: 'strings'
        }
      },
      serviceFee: {
        text: {
          key: 'service-fee-explanation',
          type: 'strings'
        }
      }
    }
  },
  gdprConsent: {
    titleText: {
      key: 'learn-offers',
      type: 'strings'
    },
    descriptionText: {
      key: 'email-phone-notifications',
      type: 'strings'
    },
    infoBlocks: [
      {
        image: {
          key: 'gdpr_consent_discounts_coupons_gifts',
          type: 'images'
        },
        text: {
          key: 'discounts-coupons-gifts',
          type: 'strings'
        }
      },
      {
        image: {
          key: 'gdpr_consent_new_shops',
          type: 'images'
        },
        text: {
          key: 'new-shops',
          type: 'strings'
        }
      }
    ],
    reminders: [
      {
        key: 'gdprConsent0',
        days: 0
      },
      {
        key: 'gdprConsent30',
        days: 30
      },
      {
        key: 'gdprConsent60',
        days: 60
      },
      {
        key: 'gdprConsent90',
        days: 90
      }
    ]
  },
  shops: {
    newTagDaysLimit: 30,
    sortingTag: 'AD',
    sortingInfo: {
      default: {
        key: 'default-sorting-explanation',
        type: 'strings'
      },
      ratings: {
        key: 'ratings-sorting-explanation',
        type: 'strings'
      },
      search: {
        key: 'search-sorting-explanation',
        type: 'strings'
      },
      defaultForCuisine: {
        key: 'cuisine-sorting-explanation',
        type: 'strings'
      },
      minimumOrder: {
        key: 'minimum-order-sorting-explanation',
        type: 'strings'
      },
      deliveryTime: {
        key: 'delivery-time-sorting-explanation',
        type: 'strings'
      }
    }
  },
  maxBrandsNo: 30,
  startWUSynergy: true,
  mainViewPollingSeconds: 45,
  cartSuggestionThreshold: 3,
  eventReplacingCharactersModel: [
    {
      character: '%',
      replacement: ''
    },
    {
      character: '€',
      replacement: ''
    },
    {
      character: '&',
      replacement: ''
    },
    {
      character: '.',
      replacement: ''
    },
    {
      character: '’',
      replacement: ''
    },
    {
      character: '"',
      replacement: ''
    },
    {
      character: "'",
      replacement: ''
    },
    {
      character: 'ά',
      replacement: 'α'
    },
    {
      character: 'έ',
      replacement: 'ε'
    },
    {
      character: 'ί',
      replacement: 'ι'
    },
    {
      character: 'ή',
      replacement: 'η'
    },
    {
      character: 'ύ',
      replacement: 'υ'
    },
    {
      character: 'ό',
      replacement: 'ο'
    },
    {
      character: 'ώ',
      replacement: 'ω'
    },
    {
      character: 'ϊ',
      replacement: 'ι'
    },
    {
      character: 'ϋ',
      replacement: 'υ'
    },
    {
      character: 'ΐ',
      replacement: 'ι'
    },
    {
      character: 'ΰ',
      replacement: 'υ'
    },
    {
      character: 'Ά',
      replacement: 'Α'
    },
    {
      character: 'Έ',
      replacement: 'Ε'
    },
    {
      character: 'Ί',
      replacement: 'Ι'
    },
    {
      character: 'Ή',
      replacement: 'Η'
    },
    {
      character: 'Ύ',
      replacement: 'Υ'
    },
    {
      character: 'Ό',
      replacement: 'Ο'
    },
    {
      character: 'Ώ',
      replacement: 'Ω'
    }
  ],
  ratingEnabled: true,
  scheduledOrdersLimit: 3,
  newFeatureSessions: 3,
  premiumThresholdDays: 30,
  GDPRfrequency: 30,
  useOldPaymentFlowAndroid: false,
  shopsRefreshMinutes: 30,
  addressChangeMinutes: 15,
  useOldBannerService: false,
  showPersonalContactDetails: true,
  appSourceNames: [
    {
      sourceApp: 'wu',
      friendlyName: "What's Up"
    },
    {
      sourceApp: 'myCosmote',
      friendlyName: 'My Cosmote'
    }
  ],
  sorting: {
    chainsThreshold: 3,
    groups1: [5, 5, 5],
    groups2: [10, 5, 5],
    chainsToPromote1: 3,
    chainsToPromote2: 5
  },
  sortingTag: 'AD',
  contest: {
    successfulRegistrationTitle: {
      key: 'first-step',
      type: 'strings'
    },
    successfulRegistrationMessage: {
      key: 'make-first-order',
      type: 'strings'
    }
  },
  bankLoyaltyInfoV2: [
    {
      bank: 'alpha',
      productType: 'bonus',
      loyaltyName: 'Bonus',
      businessVertical: 'food',
      verificationAlert: {
        contentColor: '#FFFFFF',
        title: {
          key: 'redeem-bonus-points',
          type: 'strings'
        },
        description: {
          key: 'verify-bonus-card',
          type: 'strings'
        },
        backgroundColor: '#FF8600',
        image: {
          key: 'bank_loyalty_info_bonus_logo',
          type: 'images'
        }
      },
      checkout: {
        smallLogo: {
          key: 'small_logo_bonus',
          type: 'images'
        },
        bigLogo: {
          key: 'big_logo_bonus',
          type: 'images'
        },
        pointsImage: {
          key: 'points_image_bonus',
          type: 'images'
        }
      }
    }
  ],
  tipOptions: [50, 100, 150, 200, 500],
  tip: {
    descriptionTemplate: {
      key: 'tip-explanation',
      type: 'strings'
    },
    amounts: [75, 100, 200],
    minimumAmount: 20,
    maximumAmount: 2000
  },
  ekeInfo: {
    descriptionTemplate: {
      key: 'eke-explanation',
      type: 'strings'
    },
    amounts: [75, 100, 200],
    minimumAmount: 50,
    maximumAmount: 2000,
    name: {
      key: 'child-smile',
      type: 'strings'
    },
    title: {
      key: 'support-child-smile',
      type: 'strings'
    },
    modalDescription: {
      key: 'box-supports-child-smile',
      type: 'strings'
    },
    modalIcon: {
      key: 'modal_icon_eke',
      type: 'images'
    },
    checkoutIndicator: {
      key: 'checkout_indicator_eke',
      type: 'images'
    },
    donationOptions: [50, 100, 150, 200, 500]
  },
  userEventHistoryKeys: {
    rewardsModalTiers: [
      {
        key: 'rewardsModalView500Event',
        amount: 500
      },
      {
        key: 'rewardsModalView1000Event',
        amount: 1000
      }
    ]
  },
  numberOfMainViewFilters: 20,
  offersPriority: [
    {
      name: '1+1',
      priority: 40
    },
    {
      name: 'MENU5',
      priority: 30
    },
    {
      name: 'MENU 5€',
      priority: 30
    },
    {
      name: 'COMBO',
      priority: 20
    },
    {
      name: 'OFFER',
      priority: 10
    },
    {
      name: 'Menu5',
      priority: 30
    },
    {
      name: 'Menu 5€',
      priority: 30
    },
    {
      name: 'Combo',
      priority: 20
    },
    {
      name: 'Offer',
      priority: 10
    }
  ],
  minimumShopsLimit: 5,
  updatePrompt_iOS: {
    generic: {
      title: 'update-app',
      updateMessage: 'update-app-reason',
      appVersion: '3.20.0',
      forceUpdate: true
    }
  },
  updatePrompt_Android: {
    generic: {
      title: 'update-app',
      updateMessage: 'update-app-reason',
      appVersion: '3.20.0',
      forceUpdate: true
    }
  },
  onBoardingModel: {
    skipOnBoarding: false,
    pages: [
      {
        text: {
          key: 'you-always-win',
          type: 'strings'
        },
        image: {
          key: 'whats_new_page_1',
          type: 'images'
        },
        darkImage: {
          key: 'whats_new_page_1_dark',
          type: 'images'
        },
        position: 1,
        nextButtonColor: {
          red: 255,
          green: 255,
          blue: 255,
          alpha: 1
        },
        nextButtonBackgroundColor: {
          red: 255,
          green: 134,
          blue: 0,
          alpha: 1
        },
        loginButtonColor: {
          red: 255,
          green: 255,
          blue: 255,
          alpha: 1
        }
      },
      {
        text: {
          key: 'redeem-points-to-discounts-and-gb',
          type: 'strings'
        },
        image: {
          key: 'whats_new_page_2',
          type: 'images'
        },
        darkImage: {
          key: 'whats_new_page_2_dark',
          type: 'images'
        },
        position: 2,
        nextButtonColor: {
          red: 255,
          green: 255,
          blue: 255,
          alpha: 1
        },
        nextButtonBackgroundColor: {
          red: 255,
          green: 134,
          blue: 0,
          alpha: 1
        },
        loginButtonColor: {
          red: 255,
          green: 255,
          blue: 255,
          alpha: 1
        }
      },
      {
        text: {
          key: 'food-or-groceries',
          type: 'strings'
        },
        image: {
          key: 'whats_new_page_3',
          type: 'images'
        },
        darkImage: {
          key: 'whats_new_page_3_dark',
          type: 'images'
        },
        position: 3,
        nextButtonColor: {
          red: 255,
          green: 255,
          blue: 255,
          alpha: 1
        },
        nextButtonBackgroundColor: {
          red: 255,
          green: 134,
          blue: 0,
          alpha: 1
        },
        loginButtonColor: {
          red: 255,
          green: 255,
          blue: 255,
          alpha: 1
        }
      }
    ]
  },
  rewards: {
    tutorialGifs: {
      orderProcess: {
        key: 'tutorial_gif_order_process',
        type: 'images'
      },
      pointsRedemption: {
        key: 'tutorial_gif_points_redemption',
        type: 'images'
      },
      gbRewardClaim: {
        key: 'tutorial_gif_gb_reward_claim',
        type: 'images'
      },
      firstCardPayment: {
        key: 'tutorial_gif_first_card_payment',
        type: 'images'
      }
    },
    euro: {
      footer: {
        key: '1600-for-every-4-euro',
        type: 'strings'
      }
    },
    mb: {
      title: {
        key: 'gb-with-every-order-from-cosmote',
        type: 'strings'
      },
      header: {
        key: 'pick-mobile-internet-plan',
        type: 'strings'
      },
      footer: {
        key: 'offer-available-on-cosmote',
        type: 'strings'
      },
      buttonTitle: {
        key: 'order_now',
        type: 'strings'
      },
      skins: [
        {
          title: {
            key: 'gb-with-every-order-from-cosmote',
            type: 'strings'
          },
          header: {
            key: 'pick-mobile-internet-plan',
            type: 'strings'
          },
          footer: {
            key: 'offer-available-on-cosmote',
            type: 'strings'
          },
          name: {
            key: 'cosmote-subscribers',
            type: 'strings'
          },
          segmentTitle: {
            key: 'Cosmote',
            type: 'strings'
          },
          informativeModalBanner: {
            key: 'mb_skins_cosmote_informative_modal_banner',
            type: 'images'
          },
          webInformativeModalBanner: {
            key: 'mb_skins_cosmote_web_informative_modal_banner',
            type: 'images'
          },
          logo: {
            key: 'mb_skins_cosmote_logo',
            type: 'images'
          },
          packageSelectionTitle: {
            key: 'gb-with-every-order-from-cosmote',
            type: 'strings'
          },
          freeRewardsTitle: {
            key: 'only-for-cosmote-subscribers',
            type: 'strings'
          }
        },
        {
          segment: 'whatsup',
          segmentTitle: {
            key: "What's Up",
            type: 'strings'
          },
          title: {
            key: 'gb-with-every-order-from-wu',
            type: 'strings'
          },
          header: {
            key: 'pick-mobile-internet-plan',
            type: 'strings'
          },
          footer: {
            key: 'offer-available-on-wu',
            type: 'strings'
          },
          name: {
            key: 'wu-users',
            type: 'strings'
          },
          informativeModalBanner: {
            key: 'mb_skins_whats_up_informative_modal_banner',
            type: 'images'
          },
          webInformativeModalBanner: {
            key: 'mb_skins_whats_up_web_informative_modal_banner',
            type: 'images'
          },
          logo: {
            key: 'mb_skins_whats_up_logo',
            type: 'images'
          },
          packageSelectionTitle: {
            key: 'gb-with-every-order-from-wu',
            type: 'strings'
          },
          freeRewardsTitle: {
            key: 'only-for-wu-users',
            type: 'strings'
          }
        },
        {
          segment: 'whatsupstudent',
          segmentTitle: {
            key: "What's Up Student",
            type: 'strings'
          },
          title: {
            key: 'wu-student-wins',
            type: 'strings'
          },
          header: {
            key: 'pick-mobile-internet-plan',
            type: 'strings'
          },
          footer: {
            key: 'offer-available-on-wu',
            type: 'strings'
          },
          offerIndicator: {
            key: 'mb_skins_whats_up_student_offer_indicator',
            type: 'images'
          },
          name: {
            key: "What's Up Student",
            type: 'strings'
          },
          informativeModalBanner: {
            key: 'mb_skins_whats_up_student_informative_modal_banner',
            type: 'images'
          },
          webInformativeModalBanner: {
            key: 'mb_skins_whats_up_student_web_informative_modal_banner',
            type: 'images'
          },
          logo: {
            key: 'mb_skins_whats_up_student_logo',
            type: 'images'
          },
          packageSelectionTitle: {
            key: 'we-love-we-students',
            type: 'strings'
          },
          freeRewardsTitle: {
            key: 'only-for-wu-students',
            type: 'strings'
          }
        }
      ]
    }
  },
  loyaltyInfoModel: {
    loyaltyPointsToRedeemRate: 1600,
    loyaltyRuleStepInEuroForRedemption: 4
  },
  sendPersonalisedCampaignsToOrder: true,
  referral: {
    bafV2: {
      texts: [
        {
          font: 'regular',
          color: {
            red: 42,
            green: 41,
            blue: 46,
            alpha: 1
          },
          size: 16,
          text: {
            key: 'win-easy',
            type: 'strings'
          }
        },
        {
          font: 'bold',
          color: {
            red: 42,
            green: 41,
            blue: 46,
            alpha: 1
          },
          size: 16,
          text: {
            key: '3200-points-worth-2',
            type: 'strings'
          }
        },
        {
          font: 'regular',
          color: {
            red: 42,
            green: 41,
            blue: 46,
            alpha: 1
          },
          size: 16,
          text: {
            key: 'every-friend-invite-they-win',
            type: 'strings'
          }
        },
        {
          font: 'bold',
          color: {
            red: 42,
            green: 41,
            blue: 46,
            alpha: 1
          },
          size: 16,
          text: {
            key: '6€ ',
            type: 'strings'
          }
        },
        {
          font: 'regular',
          color: {
            red: 42,
            green: 41,
            blue: 46,
            alpha: 1
          },
          size: 16,
          text: {
            key: '2-euro-on-3-orders',
            type: 'strings'
          }
        }
      ],
      image: {
        key: 'referral_baf_v2',
        type: 'images'
      },
      challengeBenefitText: {
        key: 'plus-3200-points',
        type: 'strings'
      },
      couponsEnabled: true,
      dynamicLinkTextContent: {
        title: {
          key: 'lets-box-together',
          type: 'strings'
        },
        description: {
          key: 'download-app-and-use-coupon-to-win',
          type: 'strings'
        },
        rateDescription: {
          key: 'download-app-if-not-already-and-use-coupon',
          type: 'strings'
        }
      }
    }
  },
  singleBannerSectionV2: {
    title: {
      key: 'win-3200-points',
      type: 'strings'
    },
    titleColor: '#2A292E',
    description: {
      key: 'they-win-6',
      type: 'strings'
    },
    descriptionColor: '#2A292E',
    backgroundColor: '#FBEED9',
    cta: {
      key: 'see-more',
      type: 'strings'
    },
    ctaColor: '#FF8600',
    slug: 'bring_a_friend',
    webAction: '/account/referral',
    mobileAction: 'foodbox://app/bringAFriend',
    mobileImage: {
      key: 'single_banner_section_v2_mobile',
      type: 'images'
    },
    webImage: {
      key: 'single_banner_section_v2_web',
      type: 'images'
    }
  },
  bafImageModel: {
    key: 'baf_image_model',
    type: 'images'
  },
  apiAppDownloadPointsInfo: {
    image: {
      key: 'app_download_points_info',
      type: 'images'
    },
    color: {
      red: 255,
      green: 134,
      blue: 0,
      alpha: 1
    }
  },
  smLoyaltyInfo: [
    {
      integrator: 'efresh',
      canSave: true,
      isPhysical: false,
      smName: 'e-Fresh.gr',
      longCardName: {
        key: 'e-fresh-card-long',
        type: 'strings'
      },
      shortCardName: {
        key: 'e-fresh-card-short',
        type: 'strings'
      },
      contactPhone: {
        key: '2119901000',
        type: 'strings'
      },
      pointsRedeemRate: 200,
      eurosDiscountRate: 7,
      discountRate: 700,
      superMarketLogoWide: {
        key: 'sm_loyalty_info_logo_wide_e_fresh',
        type: 'images'
      },
      orderAcceptanceLogo: {
        key: 'sm_loyalty_info_order_acceptance_logo_e_fresh',
        type: 'images'
      },
      checkoutDiscountLogo: {
        key: 'sm_loyalty_info_checkout_discount_logo_e_fresh',
        type: 'images'
      },
      bigCardPlaceholder: {
        key: 'sm_loyalty_info_big_card_placeholder_e_fresh',
        type: 'images'
      },
      smallCardPlaceholder: {
        key: 'sm_loyalty_info_small_card_placeholder_e_fresh',
        type: 'images'
      },
      orderHistoryLogo: {
        key: 'sm_loyalty_info_order_history_logo_e_fresh',
        type: 'images'
      },
      loyaltyTerms: {
        key: 'sm_loyalty_info_loyalty_terms_e_fresh',
        type: 'links'
      },
      findCardTermsUrl: {
        key: 'sm_loyalty_info_find_card_terms_url_e_fresh',
        type: 'links'
      },
      personalDataTermsUrl: {
        key: 'sm_loyalty_info_personal_data_terms_url_e_fresh',
        type: 'links'
      }
    },
    {
      integrator: 'masoutis',
      canSave: true,
      isPhysical: true,
      smName: 'Μασούτης',
      longCardName: {
        key: 'masoutis-card-long',
        type: 'strings'
      },
      shortCardName: {
        key: 'masoutis-card-short',
        type: 'strings'
      },
      contactPhone: {
        key: '2310803740',
        type: 'strings'
      },
      pointsRedeemRate: 200,
      eurosDiscountRate: 6,
      discountRate: 600,
      superMarketLogoWide: {
        key: 'sm_loyalty_info_logo_wide_masoutis',
        type: 'images'
      },
      orderAcceptanceLogo: {
        key: 'sm_loyalty_info_order_acceptance_logo_masoutis',
        type: 'images'
      },
      checkoutDiscountLogo: {
        key: 'sm_loyalty_info_checkout_discount_logo_masoutis',
        type: 'images'
      },
      bigCardPlaceholder: {
        key: 'sm_loyalty_info_big_card_placeholder_masoutis',
        type: 'images'
      },
      smallCardPlaceholder: {
        key: 'sm_loyalty_info_small_card_placeholder_masoutis',
        type: 'images'
      },
      orderHistoryLogo: {
        key: 'sm_loyalty_info_order_history_logo_masoutis',
        type: 'images'
      },
      loyaltyTerms: {
        key: 'sm_loyalty_info_loyalty_terms_masoutis',
        type: 'links'
      },
      findCardTermsUrl: {
        key: 'sm_loyalty_info_find_card_terms_url_masoutis',
        type: 'links'
      },
      personalDataTermsUrl: {
        key: 'sm_loyalty_info_personal_data_terms_url_masoutis',
        type: 'links'
      },
      barcodeGenerator: 'code128'
    },
    {
      integrator: 'kritikos',
      smName: 'Κρητικός',
      isPhysical: true,
      longCardName: {
        key: 'krhtikos-card-long',
        type: 'strings'
      },
      shortCardName: {
        key: 'krhtikos-card-short',
        type: 'strings'
      },
      contactPhone: {
        key: '2141008770',
        type: 'strings'
      },
      pointsRedeemRate: 500,
      eurosDiscountRate: 5,
      discountRate: 500,
      superMarketLogoWide: {
        key: 'sm_loyalty_info_logo_wide_kritkos',
        type: 'images'
      },
      orderAcceptanceLogo: {
        key: 'sm_loyalty_info_order_acceptance_logo_kritikos',
        type: 'images'
      },
      checkoutDiscountLogo: {
        key: 'sm_loyalty_info_checkout_discount_logo_kritikos',
        type: 'images'
      },
      bigCardPlaceholder: {
        key: 'sm_loyalty_info_big_card_placeholder_kritikos',
        type: 'images'
      },
      guestCardPlaceholder: {
        key: 'sm_loyalty_info_small_card_placeholder_kritikos',
        type: 'images'
      },
      orderHistoryLogo: {
        key: 'sm_loyalty_info_order_history_logo_kritikos',
        type: 'images'
      },
      loyaltyTerms: {
        key: 'sm_loyalty_info_loyalty_terms_kritikos',
        type: 'links'
      },
      barcodeGenerator: 'ean13'
    },
    {
      integrator: 'rabbit'
    }
  ],
  webLandingHeroes: [
    {
      keywords: [
        { key: 'coffee-?', type: 'strings' },
        { key: 'bagel-?', type: 'strings' },
        { key: 'toast-?', type: 'strings' },
        { key: 'croissant-?', type: 'strings' },
        { key: 'juice-?', type: 'strings' },
        { key: 'cheese-pie-?', type: 'strings' }
      ],
      color: '#FF8600',
      enabledOnTimeRanges: [
        {
          startHour: 3,
          startMinute: 1,
          endHour: 12,
          endMinute: 0
        }
      ],
      images: {
        'png-xl': {
          key: 'web_landing_heroes_1_png_xl',
          type: 'images'
        },
        'png-lg': {
          key: 'web_landing_heroes_1_png_lg',
          type: 'images'
        },
        'png-md': {
          key: 'web_landing_heroes_1_png_md',
          type: 'images'
        },
        'png-sm': {
          key: 'web_landing_heroes_1_png_sm',
          type: 'images'
        }
      }
    },
    {
      keywords: [
        { key: 'milk-?', type: 'strings' },
        { key: 'bread-?', type: 'strings' },
        { key: 'cereal-?', type: 'strings' },
        { key: 'fruits-?', type: 'strings' },
        { key: 'fish-?', type: 'strings' }
      ],
      color: '#FF8600',
      enabledOnTimeRanges: [
        {
          startHour: 12,
          startMinute: 1,
          endHour: 13,
          endMinute: 30
        }
      ],
      images: {
        'png-xl': {
          key: 'web_landing_heroes_2_png_xl',
          type: 'images'
        },
        'png-lg': {
          key: 'web_landing_heroes_2_png_lg',
          type: 'images'
        },
        'png-md': {
          key: 'web_landing_heroes_2_png_md',
          type: 'images'
        },
        'png-sm': {
          key: 'web_landing_heroes_2_png_sm',
          type: 'images'
        }
      }
    },
    {
      keywords: [
        { key: 'souvlaki-?', type: 'strings' },
        { key: 'cooked-?', type: 'strings' },
        { key: 'salad-?', type: 'strings' },
        { key: 'pizza-?', type: 'strings' },
        { key: 'burger-?', type: 'strings' },
        { key: 'ethnic-?', type: 'strings' }
      ],
      color: '#FF8600',
      enabledOnTimeRanges: [
        {
          startHour: 13,
          startMinute: 31,
          endHour: 16,
          endMinute: 30
        }
      ],
      images: {
        'png-xl': {
          key: 'web_landing_heroes_3_png_xl',
          type: 'images'
        },
        'png-lg': {
          key: 'web_landing_heroes_3_png_lg',
          type: 'images'
        },
        'png-md': {
          key: 'web_landing_heroes_3_png_md',
          type: 'images'
        },
        'png-sm': {
          key: 'web_landing_heroes_3_png_sm',
          type: 'images'
        }
      }
    },
    {
      keywords: [
        { key: 'coffee-?', type: 'strings' },
        { key: 'tea-?', type: 'strings' },
        { key: 'ice-cream-?', type: 'strings' },
        { key: 'dessert-?', type: 'strings' }
      ],
      color: '#FF8600',
      enabledOnTimeRanges: [
        {
          startHour: 16,
          startMinute: 31,
          endHour: 19,
          endMinute: 30
        }
      ],
      images: {
        'png-xl': {
          key: 'web_landing_heroes_4_png_xl',
          type: 'images'
        },
        'png-lg': {
          key: 'web_landing_heroes_4_png_lg',
          type: 'images'
        },
        'png-md': {
          key: 'web_landing_heroes_4_png_md',
          type: 'images'
        },
        'png-sm': {
          key: 'web_landing_heroes_4_png_sm',
          type: 'images'
        }
      }
    },
    {
      keywords: [
        {
          key: 'souvlaki-?',
          type: 'strings'
        },
        {
          key: 'pizza-?',
          type: 'strings'
        },
        {
          key: 'burger-?',
          type: 'strings'
        },
        {
          key: 'sushi-?',
          type: 'strings'
        },
        {
          key: 'ethnic-?',
          type: 'strings'
        },
        {
          key: 'chinese-?',
          type: 'strings'
        },
        {
          key: 'salad-?',
          type: 'strings'
        }
      ],
      color: '#FF8600',
      enabledOnTimeRanges: [
        {
          startHour: 19,
          startMinute: 31,
          endHour: 3,
          endMinute: 0
        }
      ],
      images: {
        'png-xl': {
          key: 'web_landing_heroes_5_png_xl',
          type: 'images'
        },
        'png-lg': {
          key: 'web_landing_heroes_5_png_lg',
          type: 'images'
        },
        'png-md': {
          key: 'web_landing_heroes_5_png_md',
          type: 'images'
        },
        'png-sm': {
          key: 'web_landing_heroes_5_png_sm',
          type: 'images'
        }
      }
    }
  ],
  webLandingEarn: {
    webp: {
      key: 'web_landing_earn_webp',
      type: 'images'
    },
    png: {
      key: 'web_landing_earn_png',
      type: 'images'
    }
  }
};
