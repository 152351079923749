import {
  Product,
  ProductPromo,
  Shop,
  ShopItems,
  Selection,
  CategoryItems,
  MapPoint,
  Address,
  Offer,
  DeliveryMethod,
  OffersPriority,
  MarketOrderCheck,
  CheckoutBagsConfig,
  GetTextByKeyType,
  CurrencyCode
} from '@box-types';
import dayjs from 'dayjs';
import orderBy from 'lodash-es/orderBy';
import { normalizeProduct } from '../products';
import { isOfferDisplayable, normalizeOffer } from '../offers';
import { filterDisplayableProducts, getShopDeliveryFeeText } from '../shops';
import { CollectionCampaign } from '../promo-campaigns';
import { getShopClosedText, getShopOperatingState } from './shop-operating-state.utils';
import { minBy } from 'lodash-es';
import groupBy from 'lodash-es/groupBy';
import { currencyFormat } from '../core';
import { getCorrespondingDeliveryFeeTier } from '../cart';
import { getPointsDistance } from '../maps';

export {
  isShopNew,
  decorateShopWithNewTag,
  shopDefaultDecorator,
  normalizeShop,
  shopHasOffers,
  removePromotedTag,
  getShopProductBuyLimit,
  getBestShopOfferType,
  isShopCampaignRecentlyAdded,
  normalizeShopItems,
  getBestProductPromo,
  requiresMakeYourOwn,
  offerHasOptions,
  filterDisplayableShopItems,
  getItemBuyLimit,
  getShopDeliveryFee,
  getCheckoutBagsConfig,
  generateShopInfo,
  getClosestShopToAddress,
  getClosestShopToAddressPerChain
};

/**
 * isShopNew will check the difference between the current date and the shop's activation date.
 * If the difference is greater than the limit, then it will return false, else it will return true.
 * @param {Shop} shop
 * @param {number} limit number of days
 * @returns {boolean} boolean
 */
function isShopNew(shop: Shop, limit?: number): boolean {
  const dateOfShopActivation = shop.monitoringInfo?.dateOfShopActivation;
  if (!dateOfShopActivation) return false;
  const activationDate = dayjs.unix(dateOfShopActivation);
  const daysLimit = limit ?? 30;
  return dayjs().diff(activationDate, 'day') < daysLimit;
}

/**
 * will use the isShopNew function to determine if the shop is new and decorate
 * the shop with the isNew property
 * @param {Shop} shop
 * @param {number} daysLimit number of days
 * @returns {Shop} shop
 */
function decorateShopWithNewTag(shop: Shop, daysLimit?: number): Shop {
  return { ...shop, isNew: isShopNew(shop, daysLimit) };
}

function shopDefaultDecorator(shop: Shop, translateFn: GetTextByKeyType): Shop {
  const operatingState = getShopOperatingState(shop);
  const categoryView = shop.businessVertical === 'groceries' && shop.menuDisplay === 'BY_CATEGORY';
  const disabledText = getShopClosedText({ ...shop, operatingState }, translateFn);
  return { ...shop, operatingState, categoryView, disabledText };
}

function normalizeShop(shop: Shop): Shop {
  return {
    ...shop,
    businessVertical: shop.businessVertical ?? 'food',
    timeslots: shop.timeslots ?? [],
    info: shop.info?.length ? shop.info : '',
    promoCampaigns: shop.promoCampaigns ?? [],
    deliveryFees: shop.deliveryFees ?? [],
    minimumAllowedDeliveryOrderTotalPrice: shop.minimumAllowedDeliveryOrderTotalPrice ?? 0,
    minimumAllowedTakeAwayOrderTotalPrice: shop.minimumAllowedTakeAwayOrderTotalPrice ?? 0,
    daasAreaShippingTime: shop.daasAreaShippingTime ?? 0,
    menuDisplay: shop.menuDisplay ?? 'BY_PRODUCTS_OFFERS',
    isProductCommentsAllowed: shop.isProductCommentsAllowed ?? true
  };
}

function shopHasOffers(shop: Shop): boolean {
  if (!shop) return false;
  return shop.offerTypes && shop.offerTypes.length > 0;
}

function removePromotedTag(shops: Shop[]): Shop[] {
  return shops.map((s) => ({ ...s, promotedTag: null }));
}

function normalizeShopItems(items: ShopItems): ShopItems {
  return {
    ...items,
    products: items.products?.map((p) => normalizeProduct(p)) ?? [],
    offers: items.offers?.map((o) => normalizeOffer(o)) ?? []
  };
}

function generateShopInfo(
  shop: Shop,
  deliveryMethod: DeliveryMethod,
  translateFn: GetTextByKeyType,
  currencyCode: CurrencyCode
): string {
  if (shop.deliveryFees.length < 2 || deliveryMethod === 'takeAway') return shop.info;
  const delFeesTextSum = getShopDeliveryFeeText(shop, deliveryMethod, currencyCode);
  const introText = translateFn('the_final_delivery_cost_depends_on_your_cart_amount', {
    _DEL_FEES_TEXT_SUM: delFeesTextSum
  });
  const orderedFees = orderBy(shop.deliveryFees, 'minCartPrice', 'asc');
  const stepTexts: string[] = orderedFees.reduce<string[]>((accumulator: string[], tier, index) => {
    const isFirstTier = index === 0;
    const isLastTier = index === orderedFees.length - 1;

    if (isLastTier) {
      const lastTier = orderedFees[orderedFees.length - 1];
      const lastTierMinPrice = Math.max(lastTier.minCartPrice, shop.minimumAllowedDeliveryOrderTotalPrice);
      const lastTierMinPriceText = currencyFormat(lastTierMinPrice, { symbolSpace: false, currencyCode });
      const lastTierFeeText =
        lastTier.fee === 0
          ? translateFn('above_min_price_free_delivery', {
              _LAST_TIER_MIN_PRICE_TEXT: lastTierMinPriceText
            })
          : translateFn('above_min_price_delivery', {
              _LAST_TIER_MIN_PRICE_TEXT: lastTierMinPriceText,
              _LAST_TIER_FEE: currencyFormat(lastTier.fee, { symbolSpace: false, currencyCode })
            });
      accumulator.push(lastTierFeeText);
      return accumulator;
    }

    // skip current tier since it cannot be redeemed
    const nextThresholdIsOverWrittenByMinimumAllowedDeliveryOrderTotalPrice =
      orderedFees[index + 1].minCartPrice <= shop.minimumAllowedDeliveryOrderTotalPrice;
    if (nextThresholdIsOverWrittenByMinimumAllowedDeliveryOrderTotalPrice) return accumulator;

    const upperLimitText = currencyFormat(orderedFees[index + 1].minCartPrice, { symbolSpace: false, currencyCode });
    const feeText = currencyFormat(tier.fee, { symbolSpace: false, currencyCode });
    const isCurrentTierOverwritten = tier.minCartPrice < shop.minimumAllowedDeliveryOrderTotalPrice;
    const bottomLimit = isCurrentTierOverwritten ? shop.minimumAllowedDeliveryOrderTotalPrice : tier.minCartPrice;
    const bottomLimitText = currencyFormat(bottomLimit, { symbolSpace: false, currencyCode });

    if (isFirstTier && !isCurrentTierOverwritten) {
      // first tier should have a minCartPrice if 0
      const translatedText = translateFn('below_limit_delivery_fee', {
        _UPPER_LIMIT_TEXT: upperLimitText,
        _FEE_TEXT: feeText
      });
      accumulator.push(`${translatedText}\n\n`);
    } else {
      const translatedText = translateFn('below_above_limit_delivery_fee', {
        _BOTTOM_LIMIT_TEXT: bottomLimitText,
        _UPPER_LIMIT_TEXT: upperLimitText,
        _FEE_TEXT: feeText
      });
      accumulator.push(`${translatedText}\n\n`);
    }
    return accumulator;
  }, []);

  const firstPart = shop.info.length ? shop.info + '\n\n' : '';
  const secondPart = introText + '\n\n';
  const thirdPart = stepTexts.join('');
  return firstPart + secondPart + thirdPart;
}

function filterDisplayableShopItems(
  items: ShopItems,
  shop: Shop,
  ignoreCategoryMatching?: boolean
): ShopItems | CategoryItems {
  const { isSuperMarket } = shop;
  const availableProducts = isSuperMarket ? items.products.filter((product) => product.available) : items.products;
  const availableOffers = items.offers.filter((offer) => offer.available);
  const products = filterDisplayableProducts(availableProducts, items.categories, ignoreCategoryMatching);
  const offers = availableOffers.filter((offer) => isOfferDisplayable(offer));
  return { ...items, products, offers };
}

function getShopProductBuyLimit(shop: Shop): number {
  const { productBuyLimit } = shop;
  if (productBuyLimit >= 2) return productBuyLimit;
}

function getItemBuyLimit(item: Product | Offer): number {
  // todo revisit with team
  return item?.maxItemsQuantity >= 1 ? item.maxItemsQuantity : 99;
}

function getBestShopOfferType(shop: Shop, offersPriority: OffersPriority[]): string {
  const shopOfferStickers = shop?.offerStickers;
  if (!shopOfferStickers?.length) return;
  if (!offersPriority?.length) return shopOfferStickers[0];

  const sortedOffersPriority = offersPriority.sort((a, b) => b.priority - a.priority); // Descending
  const bestOfferFound = sortedOffersPriority.find((bestOffer) =>
    shopOfferStickers.find((offerSticker) => offerSticker == bestOffer.name)
  );
  if (!bestOfferFound) return 'Offer';

  return bestOfferFound.name;
}

function isShopCampaignRecentlyAdded(shop: Shop, promoCampaign: CollectionCampaign): boolean {
  if (!shop.registeredCampaigns?.length) return false;
  const registeredCampaign = shop.registeredCampaigns.find(
    (campaign) => campaign.campaignName === promoCampaign.campaignName && Boolean(campaign.dateOfFirstActivation)
  );

  if (!registeredCampaign) return false;
  const dateToCheck = dayjs(registeredCampaign.dateOfFirstActivation).add(15, 'days');
  return dayjs().isBefore(dateToCheck);
}

function getBestProductPromo(productPromos: ProductPromo[]): ProductPromo {
  if (!productPromos?.length) return;
  const productPromoCampaignsTypes = ['product_promo', 'product_cuisine_promo', 'collection_drink'];
  const typeFilteredPromos = productPromos.filter((p) => productPromoCampaignsTypes.includes(p.type));
  if (!typeFilteredPromos?.length) return;
  const sortedProductPromos = orderBy(
    typeFilteredPromos,
    [
      (promo: ProductPromo) => promo.multiplier ?? 0,
      (promo: ProductPromo) => promo.points ?? 0,
      (promo: ProductPromo) => promo.priority ?? 0
    ],
    ['desc', 'desc', 'desc']
  );
  return sortedProductPromos.length > 0 && sortedProductPromos[0];
}

function requiresMakeYourOwn(selections: Selection[]): boolean {
  if (!selections?.length) return false;
  return selections.some((selection) => {
    if (!selection.necessary || Boolean(selection.disabledChoices)) return false;
    return selection.choices?.every((choice) => !choice.checked);
  });
}

function offerHasOptions(offer: Offer): boolean {
  if (!offer?.groups?.length) return false;
  return offer.groups.some((group) => group?.products?.length >= 2);
}

function getShopDeliveryFee(shop: Shop, deliveryMethod: DeliveryMethod, itemsPrice: number): number {
  if (deliveryMethod === 'takeAway' || !shop.deliveryFees.length) return 0;
  const deliveryFeeTier = getCorrespondingDeliveryFeeTier(shop.deliveryFees, itemsPrice);
  return deliveryFeeTier?.fee ?? 0;
}

function getCheckoutBagsConfig(
  shop: Shop,
  cartQuantity: number,
  marketOrderCheckResponse?: MarketOrderCheck
): CheckoutBagsConfig {
  if (!shop || !cartQuantity) return;
  const dontCalculate = shop.isSuperMarket && Boolean(marketOrderCheckResponse?.plasticBags);
  if (dontCalculate) {
    return {
      dontCalculate,
      disablePlasticBagSelection: shop.disablePlasticBagSelection,
      hasPlasticBagSelection: shop.hasPlasticBagSelection,
      plasticBagPrice: marketOrderCheckResponse.plasticBags.price,
      bagsQuantity: marketOrderCheckResponse.plasticBags.quantity
    };
  }
  return {
    dontCalculate,
    disablePlasticBagSelection: shop.disablePlasticBagSelection,
    hasPlasticBagSelection: shop.hasPlasticBagSelection,
    plasticBagPrice: shop.plasticBagPrice,
    cartQuantity
  };
}

function getClosestShopToAddress(address: Address, shops: Shop[]): Shop {
  if (!address || !shops?.length) return;
  const addressPoint: MapPoint = { lat: address.latitude, lng: address.longitude };
  const shopsWithAddress = shops.filter((shop) => shop.address);
  return minBy(shopsWithAddress, (shop) => {
    const shopPoint: MapPoint = { lat: shop.address?.latitude, lng: shop.address?.longitude };
    return getPointsDistance(addressPoint, shopPoint);
  });
}

function getClosestShopToAddressPerChain(address: Address, shops: Shop[]): Shop[] {
  if (!address || !shops?.length) return [];
  const shopsWithChain = shops.filter((shop) => shop.chain);
  const shopsPerChain = groupBy(shopsWithChain, 'chain');
  return Object.keys(shopsPerChain).reduce((closestShopsPerChain, chainKey) => {
    const shopsOfChain = shopsPerChain[chainKey];
    const closestShop = getClosestShopToAddress(address, shopsOfChain);
    closestShopsPerChain.push(closestShop);
    return closestShopsPerChain;
  }, [] as Shop[]);
}
